<style>
  div {
    font-size: 4em;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.87);
  }
</style>

<div>
  <slot />
</div>
