<script>
  import { slide } from "svelte/transition";
</script>

<style>
  hr {
    margin: 0;
    border: 0.3rem solid rgba(146, 146, 146, 0.5);
  }
</style>

<hr transition:slide />
