<script>
  import Display from "Components/UI/Display.svelte";
  import ShowList from "Components/shows/ShowList.svelte";
</script>

<style>
  article {
    margin-top: -4rem;
    padding-bottom: 4rem;
  }

  div {
    margin-left: 1rem;
    margin-right: 1rem;
    max-width: 500px;
  }

  img {
    width: 100%;
  }
</style>

<article>
  <Display src="images/background.jpg">
    <div>
      <img src="images/logo.png" alt="October Tide" />
    </div>
  </Display>
  <ShowList heading="Upcoming shows" upcoming="true" />
</article>
