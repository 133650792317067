<script>
  import { fade } from "svelte/transition";
  import { links } from "svelte-routing";
</script>

<style>
  a {
    font-family: "Times New Roman", Times, serif;
    font-weight: 500;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.87);
    text-decoration: none;
    letter-spacing: 4px;
  }
  div {
    padding-left: 1rem;
    margin-top: -6px;
  }

  a,
  div {
    transition: 200ms;
  }

  @media (max-width: 520px) {
    a {
      font-size: 24px;
      letter-spacing: 3px;
    }

    div {
      margin-top: -4px;
    }
  }
</style>

<div use:links in:fade>
  <a href="/">october tide</a>
</div>
