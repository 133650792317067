<script>
  import { Button, Icon } from "svelte-mui/src";
</script>

<style>
  div {
    position: fixed;
    bottom: 24px;
    right: 24px;
  }
</style>

<div>
  <Button color="#388E3C" icon fab raised on:click>
    <Icon path="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
  </Button>
</div>
